import { default as AboutceZrcR1pMNMeta } from "/home/runner/work/kisenya-public/kisenya-public/pages/About.vue?macro=true";
import { default as Checkssdm9UXCPA5Meta } from "/home/runner/work/kisenya-public/kisenya-public/pages/Checks.vue?macro=true";
import { default as ContactboYqHWD53yMeta } from "/home/runner/work/kisenya-public/kisenya-public/pages/Contact.vue?macro=true";
import { default as HomeK4Y4HbeRLmMeta } from "/home/runner/work/kisenya-public/kisenya-public/pages/Home.vue?macro=true";
import { default as ImprintdmVVD22tvTMeta } from "/home/runner/work/kisenya-public/kisenya-public/pages/Imprint.vue?macro=true";
import { default as PackagesmfJbf0GJECMeta } from "/home/runner/work/kisenya-public/kisenya-public/pages/Packages.vue?macro=true";
import { default as PrivacyrMPvtwHsCdMeta } from "/home/runner/work/kisenya-public/kisenya-public/pages/Privacy.vue?macro=true";
import { default as SoftwareLSZo9W2MXiMeta } from "/home/runner/work/kisenya-public/kisenya-public/pages/Software.vue?macro=true";
export default [
  {
    name: AboutceZrcR1pMNMeta?.name ?? "About",
    path: AboutceZrcR1pMNMeta?.path ?? "/About",
    meta: AboutceZrcR1pMNMeta || {},
    alias: AboutceZrcR1pMNMeta?.alias || [],
    redirect: AboutceZrcR1pMNMeta?.redirect,
    component: () => import("/home/runner/work/kisenya-public/kisenya-public/pages/About.vue").then(m => m.default || m)
  },
  {
    name: Checkssdm9UXCPA5Meta?.name ?? "Checks",
    path: Checkssdm9UXCPA5Meta?.path ?? "/Checks",
    meta: Checkssdm9UXCPA5Meta || {},
    alias: Checkssdm9UXCPA5Meta?.alias || [],
    redirect: Checkssdm9UXCPA5Meta?.redirect,
    component: () => import("/home/runner/work/kisenya-public/kisenya-public/pages/Checks.vue").then(m => m.default || m)
  },
  {
    name: ContactboYqHWD53yMeta?.name ?? "Contact",
    path: ContactboYqHWD53yMeta?.path ?? "/Contact",
    meta: ContactboYqHWD53yMeta || {},
    alias: ContactboYqHWD53yMeta?.alias || [],
    redirect: ContactboYqHWD53yMeta?.redirect,
    component: () => import("/home/runner/work/kisenya-public/kisenya-public/pages/Contact.vue").then(m => m.default || m)
  },
  {
    name: HomeK4Y4HbeRLmMeta?.name ?? "Home",
    path: HomeK4Y4HbeRLmMeta?.path ?? "/Home",
    meta: HomeK4Y4HbeRLmMeta || {},
    alias: HomeK4Y4HbeRLmMeta?.alias || [],
    redirect: HomeK4Y4HbeRLmMeta?.redirect,
    component: () => import("/home/runner/work/kisenya-public/kisenya-public/pages/Home.vue").then(m => m.default || m)
  },
  {
    name: ImprintdmVVD22tvTMeta?.name ?? "Imprint",
    path: ImprintdmVVD22tvTMeta?.path ?? "/Imprint",
    meta: ImprintdmVVD22tvTMeta || {},
    alias: ImprintdmVVD22tvTMeta?.alias || [],
    redirect: ImprintdmVVD22tvTMeta?.redirect,
    component: () => import("/home/runner/work/kisenya-public/kisenya-public/pages/Imprint.vue").then(m => m.default || m)
  },
  {
    name: PackagesmfJbf0GJECMeta?.name ?? "Packages",
    path: PackagesmfJbf0GJECMeta?.path ?? "/Packages",
    meta: PackagesmfJbf0GJECMeta || {},
    alias: PackagesmfJbf0GJECMeta?.alias || [],
    redirect: PackagesmfJbf0GJECMeta?.redirect,
    component: () => import("/home/runner/work/kisenya-public/kisenya-public/pages/Packages.vue").then(m => m.default || m)
  },
  {
    name: PrivacyrMPvtwHsCdMeta?.name ?? "Privacy",
    path: PrivacyrMPvtwHsCdMeta?.path ?? "/Privacy",
    meta: PrivacyrMPvtwHsCdMeta || {},
    alias: PrivacyrMPvtwHsCdMeta?.alias || [],
    redirect: PrivacyrMPvtwHsCdMeta?.redirect,
    component: () => import("/home/runner/work/kisenya-public/kisenya-public/pages/Privacy.vue").then(m => m.default || m)
  },
  {
    name: SoftwareLSZo9W2MXiMeta?.name ?? "Software",
    path: SoftwareLSZo9W2MXiMeta?.path ?? "/Software",
    meta: SoftwareLSZo9W2MXiMeta || {},
    alias: SoftwareLSZo9W2MXiMeta?.alias || [],
    redirect: SoftwareLSZo9W2MXiMeta?.redirect,
    component: () => import("/home/runner/work/kisenya-public/kisenya-public/pages/Software.vue").then(m => m.default || m)
  }
]