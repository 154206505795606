<template>
  <div
    class="bg-gradient-to-b from-primary-base/80 to-primary-gradient/80 rounded-12 p-32 box-border w-100% md:max-w-[36rem] max-h-[90vh] overflow-y-auto"
  >
    <div v-if="!formSuccess">
      <div class="text-white text-content-title mb-12 w-100%">Ihre Anfrage</div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-[16px] mb-12">
        <KisenyaSelect
          :ref="(el) => (formRef['type'] = el as VueElementVerifiable)"
          dark
          :options="packageTypes"
          v-model="form.type"
          class="col-span-1"
          type="primary"
          required
        />
        <KisenyaInput
          :ref="(el) => (formRef['elevators'] = el as VueElementVerifiable)"
          dark
          v-model="form.elevators"
          placeholder="Anzahl Aufzüge"
          class="col-span-1"
          required
        />
      </div>
      <div class="mb-12">
        <KisenyaInput
          :ref="(el) => (formRef['firstName'] = el as VueElementVerifiable)"
          dark
          type="text"
          v-model="form.firstName"
          placeholder="Vorname"
          required
        />
      </div>
      <div class="mb-12">
        <KisenyaInput
          :ref="(el) => (formRef['lastName'] = el as VueElementVerifiable)"
          dark
          type="text"
          v-model="form.lastName"
          placeholder="Nachname"
          required
        />
      </div>
      <div class="mb-32">
        <KisenyaInput
          :ref="(el) => (formRef['email'] = el as VueElementVerifiable)"
          dark
          type="text"
          v-model="form.email"
          placeholder="E-Mail"
          required
        />
      </div>
      <div class="text-white text-content-title mb-12 w-100%">
        Ihre Nachricht an uns
      </div>
      <div class="mb-32">
        <KisenyaInput
          v-model="form.message"
          placeholder="Ihre Nachricht"
          type="textarea"
          dark
        />
      </div>
      <div class="mb-32">
        <KisenyaCheckbox v-model="formConsent" dark>
          <template #label>
            Hiermit akzeptiere ich die von mir zur Kenntnis genommene
            Datenschutzerklärung. Sie können diese Einwilligung jederzeit
            widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns.
          </template>
        </KisenyaCheckbox>
      </div>
      <div v-if="formError">
        <KisenyaBanner
          type="error"
          :message="formError"
          @hide="formError = ''"
          class="mb-24"
        />
      </div>
      <div class="flex justify-start">
        <KisenyaButton
          @click="submitForm"
          label="Anfrage absenden"
          type="secondary"
        />
      </div>
    </div>
    <div
      v-else
      class="text-brand-white text-center flex flex-col justify-center items-center size-fit"
    >
      <div class="flex justify-center">
        <PackagesSuccessIcon :fontControlled="false" class="w-144" />
      </div>
      <div class="text-content-title mb-24">
        Ihre Anfrage wurde erfolgreich<br />
        übermittelt.
      </div>
      <div class="text-content-subtitle mb-24">
        In Kürze erhalten Sie ein Angebot mit auf Sie<br />
        zugeschnittener Rahmenvereinbarung.
      </div>
      <div class="text-content-subtitle mb-24">
        Sollten Sie bis dahin weitere Fragen haben,<br />
        zögern Sie nicht uns zu kontaktieren.
      </div>
      <div class="flex justify-center">
        <KisenyaButton
          label="Schlließen"
          type="bright"
          @click="dialogs.hideDialog('packages')"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import PackagesSuccessIcon from "~/assets/packages_sent.svg?component";

import { useDialogsStore } from "~/stores/dialogs";

const loader = useLoaderStore();

const supabase = useSupabaseClient();

const dialogs = useDialogsStore();

const packageTypes = [
  {
    label: "Basic",
    value: "basic",
  },
  {
    label: "Business",
    value: "business",
  },
  {
    label: "Enterprise",
    value: "enterprise",
  },
];

const form = ref({
  type: "basic",
  elevators: "",
  firstName: "",
  lastName: "",
  email: "",
  message: "",
});
const formConsent = ref(false);
const formSuccess = ref(false);
const formRef = ref<{ [key: string]: VueElementVerifiable }>({});
const formError = ref("");

const submitForm = async () => {
  try {
    loader.showLoader();

    for (const component of Object.values(formRef.value)) {
      if (component.verify()) {
        formError.value = "Bitte füllen Sie das Formular vollständig aus!";
        return;
      }
    }

    if (!formConsent.value) {
      formError.value =
        "Sie müssen unseren Bedingungen und Dienstleistungen zustimmen.";
      return;
    }

    const { error } = await supabase.rpc("submit_package_form", {
      email: form.value.email,
      message: form.value.message,
      firstname: form.value.firstName,
      lastname: form.value.lastName,
      elevators: form.value.elevators,
      type: form.value.type,
    });
    if (error) throw error.message;

    formError.value = "";

    form.value.email = "";
    form.value.firstName = "";
    form.value.lastName = "";
    form.value.message = "";
    form.value.elevators = "";
    form.value.type = "basic";

    formSuccess.value = true;
  } catch (error) {
    console.log(error);
    formError.value =
      (error as Error)?.message ?? "Ein interner Fehler ist aufgetreten.";
  } finally {
    loader.hideLoader();
  }
};

onMounted(() => {
  form.value.type = <string>dialogs.getDialogData("packages_type") ?? "basic";
});
</script>
