<template>
  <div>
    <div
      class="flex flex-row flex-nowrap justify-between items-center px-16 md:px-32 py-24 md:py-32 lg:py-48"
    >
      <div
        class="rounded-8 flex-shrink"
        :class="{
          'cursor-pointer': $route.path !== '/' && $route.path !== '',
        }"
        @click="$router.push('/')"
      >
        <LogoSVG :fontControlled="false" class="w-144" />
      </div>
      <div class="flex">
        <KisenyaButton
          type="outlined"
          round
          @click="menuVisibility = true"
          class="shadow-md"
        >
          <template v-slot:icon>
            <BarsIcon class="size-24" :fontControlled="false" />
          </template>
        </KisenyaButton>
      </div>
    </div>
    <div
      class="left-0 top-0 right-0 bottom-0 size-fit fixed z-50 bg-white py-24 md:py-32 lg:py-48 flex flex-col px-16 md:px-32"
      v-if="menuVisibility"
    >
      <div class="flex flex-row flex-nowrap justify-between items-center">
        <div
          class="rounded-8 flex-shrink cursor-pointer"
          @click="navigate('/')"
        >
          <LogoSVG :fontControlled="false" class="w-144" />
        </div>
        <div class="flex flex-shrink">
          <KisenyaButton
            type="outlined"
            round
            @click="menuVisibility = false"
            class="shadow-md"
          >
            <template v-slot:icon>
              <TimesIcon class="size-24" :fontControlled="false" />
            </template>
          </KisenyaButton>
        </div>
      </div>
      <div class="flex flex-col justify-items-start items-start gap-24 mt-64">
        <div
          v-for="link in links"
          :key="link.slug"
          :class="{
            'cursor-pointer hover:bg-dark/10': $route.path !== link.slug,
          }"
          class="text-content-subtitle p-8 rounded-8 relative"
          @click="navigate(link.slug)"
        >
          {{ link.label }}
          <div
            v-if="$route.path === link.slug"
            class="bg-secondary-base h-2 left-0 w-100% absolute"
          ></div>
        </div>
      </div>
      <div class="flex flex-col justify-end grow">
        <div>
          <div class="h-1 w-100% bg-stroke-color-1 mb-24"></div>
          <div class="flex flex-col gap-12 px-48">
            <KisenyaButton
              type="dark"
              label="Jetzt Vorschriften checken"
              @click="
                dialogs.showDialog('checks_duty');
                menuVisibility = false;
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import LogoSVG from "~/assets/logo_4.svg?component";
import BarsIcon from "~/assets/icons/bars-solid.svg";
import TimesIcon from "~/assets/icons/times-solid.svg";

const dialogs = useDialogsStore();
const links = [
  {
    slug: "/about",
    label: "Über uns",
  },
  {
    slug: "/software",
    label: "Liftmanagement Software",
  },
  {
    slug: "/packages",
    label: "Preise",
  },
  {
    slug: "/contact",
    label: "Kontakt",
  },
];

const router = useRouter();

const menuVisibility = ref(false);

function navigate(route: string) {
  router.push(route);
  menuVisibility.value = false;
}

function showSoftwareDialog() {
  dialogs.showDialog("software");
  menuVisibility.value = false;
}
</script>
