import { defineStore } from "pinia";

import { ref } from "vue";

export const useLoaderStore = defineStore("loader-store", () => {
  const loader = ref(false);

  const setLoader = (state: boolean) => (loader.value = state);
  const showLoader = () => (loader.value = true);
  const hideLoader = () => (loader.value = false);
  const toggleLoader = () => (loader.value = !loader.value);

  return {
    loader,

    setLoader,
    showLoader,
    hideLoader,
    toggleLoader,
  };
});
