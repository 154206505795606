<template>
  <div class="flex flex-col grow max-h-[90vh]">
    <div class="mb-24 hidden md:block">
      <img src="~/assets/gears.png" />
    </div>
    <KisenyaStepperTitle>
      {{ currentStep.title }}
    </KisenyaStepperTitle>
    <KisenyaStepperSubtitle class="mt-4" v-if="currentStep.subtitle">
      {{ currentStep.subtitle }}
    </KisenyaStepperSubtitle>
    <KisenyaStepperProgress
      class="my-32"
      :currentProgress="currentProgress"
      :maxProgress="maxProgress"
      @change-progress="handleChangeProgress"
    ></KisenyaStepperProgress>
    <KisenyaStepperContent class="self-stretch overflow-y-auto">
      <slot :name="currentStepId"></slot>
    </KisenyaStepperContent>
    <KisenyaStepperControls
      @next-progress="handleNextProgress"
      @prev-progress="handlePrevProgress"
      :hidePreviousBtn="currentProgress === 1"
      :nextButtonLabel="props.nextButtonLabel"
    ></KisenyaStepperControls>
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{
  currentStepId: string;
  steps: Array<Step>;
  nextButtonLabel?: string;
}>();
const emit = defineEmits<{
  (e: "change-progress", progress: number): void;
  (e: "next-progress"): void;
  (e: "prev-progress"): void;
}>();

const currentStep = computed(
  () => <Step>props.steps.find((step) => step.id == props.currentStepId),
);

const maxProgress = computed(() => props.steps.length);
const currentProgress = computed(() => currentStep.value.step);

const handleChangeProgress = (progress: number) => {
  emit("change-progress", progress + 1);
};
const handleNextProgress = () => {
  emit("next-progress");
};
const handlePrevProgress = () => {
  emit("prev-progress");
};
</script>
