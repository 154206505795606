<template>
  <div
    class="bg-white md:rounded-12 pt-64 pl-24 pb-24 pr-24 box-border -mx-64 w-max-content overflow-y-auto text-dark relative"
  >
    <div class="absolute top-0 right-0 z-[60]">
      <div class="rounded-full hover:bg-dark/10 cursor-pointer p-8 m-8">
        <TimesIcon
          :fontControlled="false"
          class="w-24"
          @click="dialogs.hideDialog('checks_disclaimer')"
        />
      </div>
    </div>
    <div class="text-content-subtitle text-center mb-36">
      Sie wollen nicht jedes mal wieder alle<br />
      Liftdaten ausfüllen? Nutzen Sie jetzt unsere<br />
      Software. Dort werden alle Ihre Lifte verwaltet<br />
      und Sie können ganz die Checks ausführen.
    </div>
    <div>
      <KisenyaButton
        @click="
          dialogs.hideDialog('checks_disclaimer');
          dialogs.showDialog('software');
        "
        label="Jetzt Registrieren"
        type="secondary"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import TimesIcon from "~/assets/icons/times-solid.svg";

import { useDialogsStore } from "~/stores/dialogs";

const dialogs = useDialogsStore();
</script>
