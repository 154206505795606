import { defineStore } from "pinia";
import { useRouter } from "vue-router";

import { ref, watch } from "vue";

export const useDialogsStore = defineStore("dialogs-store", () => {
  const router = useRouter();

  const activeDialogs = ref<Array<string>>([]);
  const activeDialogsData = ref<{
    [key: string]: PrimitiveType;
  }>({});

  watch(
    activeDialogs,
    () => {
      console.log(activeDialogs.value);
      if (activeDialogs.value.length) {
        document.documentElement.style.overflowY = "hidden";
      } else document.documentElement.style.overflowY = "auto";
    },
    {
      deep: true,
      immediate: true,
    },
  );

  router.beforeEach((to, from) => {
    if (to.path !== from.path && activeDialogs.value.length) {
      hideLatestDialog();
      return false;
    }
  });

  const showDialog = (dialogId: string, data?: { [key: string]: any }) => {
    if (activeDialogs.value.includes(dialogId)) return false;
    if (data) {
      for (const [key, value] of Object.entries(data)) {
        setDialogData(key, value);
      }
    }
    activeDialogs.value.push(dialogId);
    return true;
  };

  const hideDialog = (dialogId: string) => {
    const dialogIndex = activeDialogs.value.findIndex(
      (dialog) => dialog === dialogId,
    );
    if (dialogIndex === -1) return false;
    activeDialogs.value.splice(dialogIndex, 1);
    return true;
  };

  const isDialogActive = (dialogId: string) => {
    return activeDialogs.value.includes(dialogId);
  };

  const isAnyDialogActive = () => activeDialogs.value.length > 0 ? true : false;

  const hideLatestDialog = () => activeDialogs.value.pop();

  const getDialogData = (dataId: string) => activeDialogsData.value[dataId];
  const setDialogData = (
    dataId: string,
    data: PrimitiveType,
  ) => (activeDialogsData.value[dataId] = data);

  const hideAllDialogs = () => (activeDialogs.value = []);

  return {
    hideAllDialogs,

    activeDialogs,

    showDialog,
    hideDialog,

    isDialogActive,
    isAnyDialogActive,

    hideLatestDialog,

    getDialogData,
    setDialogData,
  };
});
