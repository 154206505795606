<template>
  <div>
    <div class="text-pricing-point text-dark/40 mb-8" v-if="!props.required">
      optional
    </div>
    <div
      v-if="$slots.label"
      :class="{
        'mb-8 font-medium': true,
      }"
    >
      <slot name="label"></slot>
    </div>
    <div
      :class="{
        'flex gap-12': true,
        'flex-col': props.direction === 'vertical',
        'flex-row flex-wrap': props.direction === 'horizontal',
      }"
    >
      <KisenyaRadio
        v-for="option of props.options"
        :key="option.value"
        :value="option.value"
        :dark="props.dark"
        v-model="modelValue"
      >
        <template #label>{{ option.label }}</template>
      </KisenyaRadio>
    </div>
    <div
      :class="{
        'mt-4 ': true,
        'text-caption text-error': props.dark === false,
        'text-caption-dark text-error-dark': props.dark === true,
      }"
      v-if="errorMessage"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>
<script lang="ts" setup>
const modelValue = defineModel({
  type: String,
  default: "",
});

const errorMessage: Ref<KisenyaInputErrorMessage> = ref(false);

const slots = defineSlots<{
  label?: () => any;
}>();

const props = withDefaults(defineProps<KisenyaRadioGroupProps>(), {
  direction: "horizontal",
  required: false,
});

const verify = () => {
  if (props.required && !modelValue.value)
    errorMessage.value = "Dieses Feld ist erforderlich.";
  else errorMessage.value = false;

  return errorMessage.value;
};

defineExpose({
  verify,
});
</script>
