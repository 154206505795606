<template>
  <div
    class="border-black/10 pt-8 mt-8 bg-white flex grow items-end justify-center md:justify-end gap-24"
  >
    <KisenyaButton
      class="flex-grow md:flex-grow-0"
      type="outlined"
      @click="$emit('prev-progress')"
      :label="props.prevButtonLabel"
      v-if="!props.hidePreviousBtn"
    ></KisenyaButton>
    <KisenyaButton
      class="flex-grow md:flex-grow-0"
      type="primary"
      @click="$emit('next-progress')"
      :label="props.nextButtonLabel"
    ></KisenyaButton>
  </div>
</template>
<script setup lang="ts">
const props = withDefaults(defineProps<KisenyaStepperControlsProps>(), {
  nextButtonLabel: "Nächster Schritt",
  prevButtonLabel: "Zurück",
  hidePreviousBtn: false,
});

const emit = defineEmits<{
  (e: "next-progress"): void;
  (e: "prev-progress"): void;
}>();
</script>
