<template>
  <div class="flex gap-12">
    <div
      @click="$emit('change-progress', index)"
      v-for="(_, index) in maxProgressAsArray"
      :class="{
        'w-32 md:w-64 border-2 rounded-full hover:cursor-pointer hover:opacity-70': true,
        'border-progress-faded': index + 1 > props.currentProgress,
        'border-primary-base': index + 1 <= props.currentProgress,
      }"
    ></div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{ currentProgress: number; maxProgress: number }>();
const maxProgressAsArray = computed(() => new Array(props.maxProgress));
const emit = defineEmits<{ (e: "change-progress", progress: number): void }>();
</script>
