<template>
  <div
    class="bg-[#F0F0F0] rounded-12 col-span-3 hidden md:flex flex-col py-16 px-24"
  >
    <div class="self-center mb-24">
      <img src="~/assets/logo_alt.png" />
    </div>
    <div class="border-1 border-stroke-color-1 w-100% mb-24"></div>
    <div>
      <div class="mb-32">
        <div class="text-challenges-caption">Nachricht senden</div>
        <div
          class="border-1 border-stroke-color-1 rounded-12 px-8 py-4 flex gap-4 justify-center cursor-pointer hover:bg-stroke-color-1/20 w-max-content"
        >
          <div>
            <MailIcon :fontControlled="false" class="w-16" />
          </div>
          <div class="text-caption font-bold self-center">
            service@kisenya.de
          </div>
        </div>
      </div>
      <div class="mb-32">
        <div class="text-challenges-caption">Rufen Sie uns an</div>
        <div class="text-content-subtitle mb-8">
          Unsere Erreichbarkeit ist von Mo-Fr von 08:00 Uhr bis 17:00 Uhr
        </div>
        <div
          class="border-1 border-stroke-color-1 rounded-12 px-8 py-4 flex gap-4 justify-center cursor-pointer hover:bg-stroke-color-1/20 w-max-content"
        >
          <div>
            <PhoneIcon :fontControlled="false" class="w-16" />
          </div>
          <div class="text-caption font-bold self-center">+49 15792388694</div>
        </div>
      </div>
      <div class="mb-32">
        <div class="text-challenges-caption">Besuchen Sie uns</div>
        <div class="text-content-subtitle mb-8">
          Besuchen Sie uns persönlich
        </div>
        <div
          class="border-1 border-stroke-color-1 rounded-12 px-8 py-4 flex gap-4 justify-center cursor-pointer hover:bg-stroke-color-1/20 w-max-content"
        >
          <div>
            <HouseIcon :fontControlled="false" class="w-16" />
          </div>
          <div class="text-mini-caption font-bold self-center">
            Ludwig-Pfau-Str.1, 70176 Stuttgart
          </div>
        </div>
      </div>
    </div>
    <div class="border-1 border-stroke-color-1 w-100% mb-24"></div>
    <div>
      <div class="text-content-subtitle mb-12">
        Nutzen Sie unsere kostenlose Liftmanagement Software
      </div>
      <div>
        <KisenyaButton
          @click="dialogs.showDialog('software')"
          label="Jetzt Registrieren"
          type="secondary"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import MailIcon from "~/assets/icons/envelope.svg";
import PhoneIcon from "~/assets/icons/phone-solid.svg";
import HouseIcon from "~/assets/icons/home-solid.svg";

import { useDialogsStore } from "~/stores/dialogs";

const dialogs = useDialogsStore();
</script>
