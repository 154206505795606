<template>
  <div class="p-12 -mx-64 w-[100vw] h-[100vh] bg-white text-dark">
    <div class="absolute top-8 right-8 z-[60]">
      <div class="rounded-full hover:bg-dark/10 cursor-pointer p-4">
        <TimesIcon
          :fontControlled="false"
          class="w-24"
          @click="dialogs.hideDialog('checks_offer')"
        />
      </div>
    </div>
    <div v-if="step === -1" class="grid grid-cols-12 h-100%">
      <ChecksDialogsSidebarA />
      <div
        class="md:hidden col-span-12 flex flex-col size-fit justify-center place-items-center"
      >
        <div>
          <div class="flex justify-center mb-32">
            <ChecksIcon3 :fontControlled="false" class="h-192" />
          </div>
          <div class="mb-16 text-content-title text-center">Angebotscheck</div>
          <div class="mb-24 text-content-subtitle text-center">
            Sie haben ein Angebot vorliegen und können es fachlich und preislich
            nicht bewerten. Dafür gibt es unseren Angebotscheck mit dem wir Ihr
            vorliegendes Angebot prüfen und sofern sinnhaft, eine Alternative
            anfordern.
          </div>
          <div class="flex justify-center">
            <KisenyaButton
              type="primary"
              label="Starte Angebotsprüfung"
              @click="
                step = 1;
                stepId = 'a';
                dialogs.showDialog('checks_disclaimer');
              "
            >
              <template #icon>
                <RightArrowIcon
                  :fontControlled="false"
                  class="w-24 fill-brand-white stroke-brand-white"
                />
              </template>
            </KisenyaButton>
          </div>
        </div>
      </div>
      <div class="p-24 hidden md:block md:col-span-9 relative">
        <div class="mb-16 text-content-title">Angebotscheck</div>
        <div class="mb-24 text-content-subtitle">
          Sie haben ein Angebot vorliegen und können es fachlich und preislich
          nicht bewerten. Dafür gibt es unseren Angebotscheck mit dem wir Ihr
          vorliegendes Angebot prüfen und sofern sinnhaft, eine Alternative
          anfordern.
        </div>
        <div class="border-1 border-stroke-color-1 w-100% mb-64"></div>
        <div><ChecksIcon3 :fontControlled="false" class="h-[24rem]" /></div>
        <div class="flex justify-end absolute right-0 bottom-0">
          <KisenyaButton
            type="primary"
            label="Starte Angebotsprüfung"
            @click="
              step = 1;
              stepId = 'a';
              dialogs.showDialog('checks_disclaimer');
            "
          >
            <template #icon>
              <RightArrowIcon
                :fontControlled="false"
                class="w-24 fill-brand-white stroke-brand-white"
              />
            </template>
          </KisenyaButton>
        </div>
      </div>
    </div>
    <div v-else-if="step === 4" class="grid grid-cols-12 h-100%">
      <ChecksDialogsSidebarB />
      <div
        class="p-24 col-span-12 md:col-span-9 relative overflow-scroll min-h-full flex flex-col justify-center items-center gap-24 text-dark"
      >
        <div class="flex justify-center">
          <CheckMarkIcon :fontControlled="false" class="w-32" />
        </div>
        <div class="text-content-title">
          Ihre Daten wurden erfolgreich übermittelt!
        </div>
        <div class="text-content-subtitle">
          In Kürze werden Sie eine E-Mail mit der Auswertung erhalten.
        </div>
        <div class="flex flex-col md:flex-row justify-center gap-16 mb-32">
          <KisenyaButton
            @click="resetForm"
            type="outlined"
            label="Test erneut durchführen"
          />
        </div>
      </div>
      <div class="hidden md:block">
        <div class="border-1 border-stroke-color-1 mb-32"></div>
        <div class="flex items-center gap-32">
          <div>
            <ArrowIcon :fontControlled="false" class="w-48" />
          </div>
          <div class="bg-[#DDE8F4] p-16 rounded-12">
            Sie wollen Ihre Aufzüge effizient<br />
            verwalten und sich beraten lassen?
          </div>
        </div>
      </div>
    </div>
    <div v-else class="grid grid-cols-12 h-100%">
      <ChecksDialogsSidebarB />
      <div class="p-24 col-span-12 md:col-span-9 relative overflow-scroll">
        <div class="mb-24">
          <img src="~/assets/gears.png" />
        </div>
        <KisenyaStepper
          :currentStepId="stepId"
          :steps="steps"
          @next-progress="setProgressTo(step + 1)"
          @prev-progress="setProgressTo(step - 1)"
          @change-progress="setProgressTo"
          :nextButtonLabel="step === 3 ? 'Abschließen' : 'Nächster Schritt'"
        >
          <template #a>
            <div>
              <KisenyaFormBuilder
                :ref="(el) => (stepsRef['a'] = el as VueElementVerifiable)"
                v-model="form"
                :form="offerStepA"
              /></div
          ></template>
          <template #b>
            <div>
              <KisenyaFormBuilder
                :ref="(el) => (stepsRef['b'] = el as VueElementVerifiable)"
                v-model="form"
                :form="offerStepB"
              /></div
          ></template>
          <template #c>
            <div>
              <KisenyaFormBuilder
                :ref="(el) => (stepsRef['c'] = el as VueElementVerifiable)"
                v-model="form"
                :form="offerStepC"
              /></div
          ></template>
        </KisenyaStepper>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import RightArrowIcon from "~/assets/icons/arrow-right-solid.svg?component";
import TimesIcon from "~/assets/icons/times-solid.svg?component";
import CheckMarkIcon from "~/assets/icons/checkmark-circle.svg";

import ChecksIcon3 from "~/assets/checks_3.svg?component";
import ArrowIcon from "~/assets/arrow_1.svg?component";

const supabase = useSupabaseClient();

const router = useRouter();
const route = useRoute();

router.replace({ path: route.path, hash: "#Angebotscheck" });

import { useDialogsStore } from "~/stores/dialogs";
import { useLoaderStore } from "~/stores/loader";

const dialogs = useDialogsStore();
const loader = useLoaderStore();

const step = ref(-1);
const stepId = ref("");

const form = ref({
  a1: [],
  b1: "",
  b2: "",
  b3: "",
  b4: "",
  c1: "",
  c2: "",
  c3: "",
  c4: "",
  c5: false,
});

const resetForm = () => {
  step.value = -1;
  stepId.value = "";
  form.value = {
    a1: [],
    b1: "",
    b2: "",
    b3: "",
    b4: "",
    c1: "",
    c2: "",
    c3: "",
    c4: "",
    c5: false,
  };
};

const steps = ref<Step[]>([
  {
    id: "a",
    step: 1,
    title: "Angebotsupload",
  },
  {
    id: "b",
    step: 2,
    title: "Weitere Informationen zu Ihrem Aufzug",
  },
  {
    id: "c",
    step: 3,
    title: "Ihre Kontaktinformationen",
  },
]);

const stepsRef = ref<{ [key: string]: VueElementVerifiable }>({});

const emailRule =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

const setProgressTo = async (target: number) => {
  const newStepId = steps.value.find(
    (stepInfo) => stepInfo.step === target,
  )?.id;

  if (target > step.value) {
    const currentStepRef = stepsRef.value[stepId.value] as VueElementVerifiable;
    const verification = currentStepRef.verify();

    if (!verification) {
      return;
    }
  }

  if (target === 4) {
    try {
      loader.showLoader();

      const formData = new FormData();

      for (const [key, value] of Object.entries(form.value)) {
        console.log(key, typeof value);

        if (Array.isArray(value)) {
          console.log(value);
          value.forEach((item: KisenyaUploaderFile) =>
            formData.append(key, item.file),
          );
        } else formData.append(key, String(value));
      }

      const { error } = await supabase.functions.invoke(
        "offer-check-form-submission",
        {
          body: formData,
        },
      );

      if (error) throw new Error(error);

      step.value = 4;
      stepId.value = "";
    } catch (error) {
      console.log(error);
    } finally {
      loader.hideLoader();

      return;
    }
  }

  if (newStepId) {
    step.value = target;
    stepId.value = newStepId;
  }
};

const offerStepA: KisenyaFormBuilderFormField[] = [
  {
    id: "a1",
    type: "uploader",
    args: {
      required: true,
    } as KisenyaUploaderProps,
    slots: {
      label:
        "Laden Sie ein Angebot hoch. Dieses wird für Sie fachlich und juristisch geprüft.",
    },
  },
];
const offerStepB: KisenyaFormBuilderFormField[] = [
  {
    id: "b1",
    type: "input",
    args: {
      type: "text",
      placeholder: "Musterstraße 12, 12345 Musterstadt",
      required: true,
    } as KisenyaInputProps,
    slots: {
      label: "Standort des Aufzugs",
    },
  },
  {
    id: "b2",
    type: "input",
    args: {
      type: "text",
      placeholder: "12345678",
      required: true,
    } as KisenyaInputProps,
    slots: {
      label: "Fabriknummer",
    },
  },
  {
    id: "b3",
    type: "input",
    args: {
      required: true,
      placeholder: "Schindler, OTIS, etc...",
    } as KisenyaInputProps,
    slots: {
      label: "Hersteller",
    },
  },
  {
    id: "b4",
    type: "input",
    args: {
      type: "text",
      placeholder: "2020",
      required: true,
    } as KisenyaInputProps,
    slots: {
      label: "Baujahr",
    },
  },
];

const offerStepC: KisenyaFormBuilderFormField[] = [
  {
    id: "c1",
    type: "input",
    args: {
      type: "text",
      placeholder: "max@mustermann.de",
      required: true,
      rules: [
        (val: string) => emailRule.test(val) || "Please provide a valid email.",
      ],
    } as KisenyaInputProps,
    slots: {
      label: "Ihre E-Mail Adresse",
    },
  },
  {
    id: "c2",
    type: "input",
    args: {
      type: "text",
      placeholder: "Max Mustermann",
      required: true,
    } as KisenyaInputProps,
    slots: {
      label: "Ihr Name",
    },
  },
  {
    id: "c3",
    type: "input",
    args: {
      type: "text",
      placeholder: "123456789",
      required: true,
    } as KisenyaInputProps,
    slots: {
      label: "Telefonnummer",
    },
  },
  {
    id: "c4",
    type: "input",
    args: {
      type: "text",
      placeholder: "Schreiben Sie hier",
    } as KisenyaInputProps,
    slots: {
      label: "Gibt es noch weitere Bemerkungen, die Sie uns mitteilen wollen?",
    },
  },
  {
    id: "c5",
    type: "checkbox",
    args: {
      required: true,
    } as KisenyaCheckboxProps,
    slots: {
      label:
        "Hiermit akzeptiere ich die von mir zur Kenntnis genommene Datenschutzerklärung. Sie können diese Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns.",
    },
  },
];
</script>
