<template>
  <div>
    <Navigation />
    <NuxtPage
      class="px-16 md:px-32 lg:px-64 py-12 md:py-16 lg:py-24 min-h-[70vh] mb-64 overflow-x-hidden"
    />
    <Footer />
    <div
      @click.self="dialogs.hideLatestDialog()"
      v-for="(dialog, index) in dialogs.activeDialogs"
      :key="index"
      class="bg-black/80 fixed left-0 top-0 right-0 bottom-0 z-30 flex place-items-center place-content-center px-64"
    >
      <component :is="getDialogComponent(dialog)?.component" />
      <!-- <PackagesDialog v-if="dialogs.isDialogActive('packages')" /> -->
      <!-- <ChecksDialogsOffer v-if="dialogs.isDialogActive('checks_offer')" /> -->
      <!-- <ChecksDialogsOperating -->
      <!-- v-if="dialogs.isDialogActive('checks_operating')" -->
      <!-- /> -->
      <!-- <ChecksDialogsDuty v-if="dialogs.isDialogActive('checks_duty')" /> -->
      <!-- <SoftwareDialog v-if="dialogs.isDialogActive('software')" /> -->
    </div>
    <KisenyaLoader v-if="loader.loader" />
  </div>
</template>
<script setup lang="ts">
import { useDialogsStore } from "~/stores/dialogs";
import { useLoaderStore } from "~/stores/loader";

const dialogs = useDialogsStore();
const loader = useLoaderStore();

const dialogComponents = [
  {
    id: "packages",
    component: resolveComponent("PackagesDialog"),
  },
  {
    id: "checks_offer",
    component: resolveComponent("ChecksDialogsOffer"),
  },
  {
    id: "checks_operating",
    component: resolveComponent("ChecksDialogsOperating"),
  },
  {
    id: "checks_duty",
    component: resolveComponent("ChecksDialogsDuty"),
  },
  {
    id: "software",
    component: resolveComponent("SoftwareDialog"),
  },
  {
    id: "checks_disclaimer",
    component: resolveComponent("ChecksDialogsDisclaimer"),
  },
];

const getDialogComponent = (dialogId: string) =>
  dialogComponents.find((component) => component.id === dialogId);
</script>
