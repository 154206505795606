<template>
  <div class="flex gap-12 cursor-pointer select-none" @click="select">
    <div class="flex">
      <div
        :class="{
          'place-self-center border-1 size-20 rounded-full relative': true,
          'border-primary-base':
            props.dark === false && modelValue === props.value,
          'border-brand-white bg-primary-base text-brand-white':
            props.dark === true,
        }"
      >
        <div
          v-if="modelValue === props.value"
          class="absolute left-0 right-0 bottom-0 top-0 size-fit flex place-items-center place-content-center"
        >
          <div
            :class="{
              'size-12 rounded-full': true,
              'bg-white': props.dark === true,
              'bg-primary-base': props.dark === false,
            }"
          ></div>
        </div>
      </div>
    </div>
    <div
      :class="{
        'text-content-subtitle self-center': true,
        'text-brand-white': dark === true,
        'text-dark': dark === false,
      }"
    >
      <slot name="label"></slot>
    </div>
  </div>
</template>
<script setup lang="ts">
const modelValue = defineModel({
  type: String,
  default: "",
});

const props = withDefaults(defineProps<KisenyaRadioProps>(), {
  dark: false,
});
const slots = defineSlots<{ label?: () => any }>();

const select = () => (modelValue.value = props.value);
</script>
