<template>
  <div
    class="progress-bar"
    :style="{ width: size + 'px', height: size + 'px' }"
  >
    <svg :width="size" :height="size" viewBox="0 0 100 100">
      <circle cx="50" cy="50" r="40" class="progress-background" />
      <circle
        cx="50"
        cy="50"
        r="40"
        :style="{
          stroke,
        }"
        class="progress-fill"
        :stroke-dasharray="dashArray"
        :stroke-dashoffset="dashOffset"
      />
    </svg>
    <div class="progress-text" :style="{ color: stroke }" v-if="!label">
      {{ percentage }}%
    </div>
    <div class="progress-text" :style="{ color: stroke }" v-else>
      {{ props.label }}
    </div>
  </div>
</template>
<script setup lang="ts">
const modelValue = defineModel({
  required: true,
  type: Number,
  default: 0,
});

const props = withDefaults(defineProps<KisenyaProgressProps>(), {
  maxValue: 100,
  size: 200,
  minBoundary: 50,
  maxBoundary: 100,
});

const percentage = computed(() =>
  Math.round((modelValue.value / props.maxValue) * 100),
);
const circumference = computed(() => 2 * Math.PI * 40);
const dashArray = computed(
  () => `${circumference.value} ${circumference.value}`,
);
const dashOffset = computed(
  () => circumference.value * (1 - percentage.value / 100),
);

const stroke = computed(() => {
  if (modelValue.value < props.minBoundary) return "#B80B00";
  else if (modelValue.value < props.maxBoundary) return "#DF6B00";
  else return "#00B84A";
});
</script>
<style scoped>
.progress-bar {
  position: relative;
}

svg {
  transform: rotate(-90deg); /* Start at the top */
}

.progress-background {
  fill: none;
  stroke: #e0e0e0; /* Light gray background */
  stroke-width: 10;
}

.progress-fill {
  fill: none;
  stroke-width: 10;
  transition: stroke-dashoffset 0.5s ease-in-out;
}

.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
}
</style>
