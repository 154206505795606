<template>
  <div>
    <div
      v-if="$slots.label"
      :class="{
        'mb-8 font-medium': true,
      }"
    >
      <slot name="label"></slot>
    </div>
    <div class="flex relative">
      <select
        v-model="modelValue"
        class="pl-12 pr-48 py-16 rounded-8 grow focus:outline-none"
        :class="{
          'border-1 border-dark/40': props.type === 'outlined' && !errorMessage,
          'border-1 border-error': errorMessage,
        }"
      >
        <option value="" disabled selected hidden>
          {{ props.placeholder }}
        </option>
        <option
          v-for="option in props.options"
          :key="option.value"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
      <AngleDown :fontControlled="false" class="h-24 right-3 top-4 absolute" />
    </div>
    <div
      :class="{
        'mt-4 ': true,
        'text-caption text-error': props.dark === false,
        'text-caption-dark text-error-dark': props.dark === true,
      }"
      v-if="errorMessage"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>
<script lang="ts" setup>
import AngleDown from "~/assets/icons/angle-down-solid.svg";

const modelValue = defineModel({
  required: true,
  type: String,
  default: "",
});

const slots = defineSlots<{
  label?: () => any;
}>();

const props = defineProps<KisenyaSelectProps>();

const errorMessage: Ref<KisenyaInputErrorMessage> = ref(false);

const verify = () => {
  if (props.required && modelValue.value === "")
    errorMessage.value = "Dieses Feld ist erforderlich.";
  else errorMessage.value = "";
  return errorMessage.value;
};

defineExpose({
  verify,
});
</script>
<style scoped>
select {
  -webkit-appearance: none; /* Hide default arrow in WebKit browsers */
  -moz-appearance: none; /* Hide default arrow in Firefox */
  appearance: none; /* Hide default arrow in other browsers */
}
</style>
