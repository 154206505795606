<template>
  <div>
    <div
      class="flex items-start gap-12 cursor-pointer select-none"
      @click="toggle"
    >
      <div class="flex">
        <div
          :class="{
            'flex place-self-center border-1 size-20 rounded-4 mt-[0.0625rem]': true,
            'border-dark': props.dark === false && modelValue === false,
            'border-brand-white bg-transparent text-brand-white':
              props.dark === true,
            'border-primary-base bg-primary-base text-brand-white':
              props.dark === false && modelValue === true,
          }"
        >
          <div class="size-fit flex place-content-center">
            <svg
              v-if="modelValue"
              class="place-self-center size-16"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m4.5 12.75 6 6 9-13.5"
              />
            </svg>
          </div>
        </div>
      </div>
      <div
        :class="{
          'text-content-subtitle': true,
          'text-brand-white': dark === true,
          'text-dark': dark === false,
        }"
      >
        <slot name="label"></slot>
        <div
          :class="{
            'mt-4 ': true,
            'text-caption text-error': props.dark === false,
            'text-caption-dark text-error-dark': props.dark === true,
          }"
          v-if="errorMessage"
        >
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const modelValue = defineModel({
  type: Boolean,
  default: false,
});

const errorMessage: Ref<KisenyaInputErrorMessage> = ref(false);

const props = withDefaults(defineProps<KisenyaCheckboxProps>(), {
  dark: false,
  required: false,
});

const slots = defineSlots<{ label?: () => any }>();

const toggle = () => (modelValue.value = !modelValue.value);

const verify = () => {
  if (props.required && !modelValue.value)
    errorMessage.value = "Dieses Feld ist erforderlich.";
  else errorMessage.value = false;

  return errorMessage.value;
};

defineExpose({
  verify,
});
</script>
