<template>
  <div
    class="bg-white md:rounded-12 p-64 box-border -mx-64 w-[100vw] h-[100vh] md:w-100% lg:max-w-[54rem] lg:h-auto overflow-y-auto text-dark relative"
  >
    <div class="absolute top-0 right-0 z-[60]">
      <div class="rounded-full hover:bg-dark/10 cursor-pointer p-8 m-8">
        <TimesIcon
          :fontControlled="false"
          class="w-24"
          @click="dialogs.hideDialog('software')"
        />
      </div>
    </div>
    <div class="flex flex-col lg:items-center lg:justify-center h-[100%]">
      <div class="text-hero text-center mb-16">
        Unsere Software ist bald verfügbar!
      </div>
      <div class="text-content-subtitle text-center mb-36">
        Unsere Software befindet sich noch in der Entwicklung und steht bald zur
        Verfügung. Sollten Sie<br class="hidden md:block" />
        bereits jetzt
        <span class="text-primary-base font-bold">Leistungen</span> in Anspruch
        nehmen wollen oder
        <span class="text-primary-base font-bold">Fragen</span> haben,
        kontaktieren Sie uns<br class="hidden md:block" />
        gerne über unser Kontaktformular.
      </div>
      <div class="flex justify-center mb-32">
        <KisenyaButton
          label="Jetzt kontaktieren"
          type="secondary"
          @click="
            dialogs.hideAllDialogs();
            $router.push('/contact');
          "
        />
      </div>
    </div>
    <div
      class="absolute bottom-0 left-0 right-0 hidden lg:static lg:flex justify-center z-50"
    >
      <SoftwareIllustration
        :fontControlled="false"
        class="w-100% md:h-[22rem] z-50"
      />
    </div>
    <SoftwareBackground
      :fontControlled="false"
      class="absolute lg:block hidden bottom-0 right-0 z-40 w-[60%]"
    />
  </div>
</template>
<script setup lang="ts">
import SoftwareIllustration from "~/assets/software.svg?component";
import SoftwareBackground from "~/assets/software_bg.svg?component";
import TimesIcon from "~/assets/icons/times-solid.svg";

import { useDialogsStore } from "~/stores/dialogs";

const dialogs = useDialogsStore();
</script>
