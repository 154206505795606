import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin({
  name: "sentry",
  parallel: true,
  async setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp;

    const config = useRuntimeConfig();

    const router = useRouter();

    if (config.public.SENTRY_ENVIRONMENT == "development") return;

    Sentry.init({
      app: vueApp,
      dsn: config.public.SENTRY_DSN_PUBLIC ?? null,
      release: (config.public.GITHUB_SHA as string) ?? undefined,
      integrations: [
        Sentry.browserTracingIntegration({
          router,
        }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      environment: config.public.SENTRY_ENVIRONMENT,
      debug: config.public.SENTRY_ENVIRONMENT == "production" ? false : true,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: config.public.SENTRY_TRACES_SAMPLE_RATE,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

      // This sets the sample rate. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: config.public.SENTRY_REPLAY_SAMPLE_RATE,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: config.public.SENTRY_ERROR_REPLAY_SAMPLE_RATE,
    });
  },
});
