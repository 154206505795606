<template>
  <div
    :class="{
      'select-none rounded-30 cursor-pointer text-center flex gap-16': true,
      'justify-center': !$slots.icons,
      'bg-primary-base text-brand-white': props.type === 'primary',
      'bg-gradient-to-b from-secondary-base to-secondary-gradient text-brand-white text-challenges-caption':
        props.type === 'secondary',
      'border-1 border-stroke-color-1': props.type === 'outlined',
      'bg-dark text-white': props.type === 'dark',
      'bg-white text-dark': props.type === 'bright',
      'p-8 hover:bg-dark/10 border-0 rounded-full': props.round,
      'px-24 py-12 rounded-full': !props.round,
    }"
  >
    <span v-if="label">
      {{ label }}
    </span>
    <slot name="icon" class="pl-20" v-if="$slots.icon"></slot>
  </div>
</template>
<script lang="ts" setup>
const props = withDefaults(defineProps<KisenyaButtonProps>(), {
  round: false,
  label: "",
});
const slots = defineSlots<{
  icon: () => any;
}>();
</script>
