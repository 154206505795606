<template>
  <div class="text-dark">
    <input
      multiple
      class="hidden"
      ref="fileUploaderRef"
      type="file"
      @change="onFileChange"
    />
    <div class="text-pricing-point text-dark/40 mb-8" v-if="!props.required">
      optional
    </div>
    <div class="text-privacy-subtitle mb-12"><slot name="label"></slot></div>
    <div
      @dragover.prevent
      @drop="addFiles"
      class="border-1 border-dark border-dashed cursor-pointer rounded-12 hover:bg-dark/5"
      :class="{
        'border-dark': !errorMessage,
        'border-negative': errorMessage,
      }"
      @click="triggerFileUploader"
    >
      <div
        v-if="modelValue.length === 0"
        class="py-24 flex flex-col place-content-center place-items-center"
      >
        <div><UploadIcon :fontControlled="false" class="w-24" /></div>
        <div class="text-caption">
          Klicken zum upload oder Anhang reinziehen
        </div>
      </div>
      <div class="flex flex-wrap gap-8 p-24" v-else>
        <div
          v-for="(file, index) in modelValue"
          :key="file.id"
          class="flex flex-row gap-16 align-middle border-1 border-dark/50 px-16 py-8 rounded-12 hover:bg-dark/5"
          @click.stop="modelValue.splice(index, 1)"
        >
          <TimesIcon :fontControlled="false" class="w-16" />
          <div>
            {{ file.file.name }}
            <span class="text-caption"
              >({{ bytesToMegabytes(file.file.size) }} MB)</span
            >
          </div>
        </div>
        <div
          class="flex flex-row gap-8 align-middle border-1 border-negative/90 bg-negative/90 px-16 py-8 rounded-12 hover:bg-negative/50 hover:border-negative/10 text-brand-white"
          @click.stop="modelValue.splice(0, modelValue.length)"
        >
          <TrashIcon :fontControlled="false" class="w-16" />
          <div>Clear</div>
        </div>
      </div>
    </div>
    <div class="mt-4 text-caption text-error" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>
<script setup lang="ts">
import TimesIcon from "~/assets/icons/times-solid.svg";
import TrashIcon from "~/assets/icons/trash-alt-solid.svg";
import UploadIcon from "~/assets/icons/cloud-upload-alt-solid.svg";

function generateID(length = 8) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let id = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    id += characters.charAt(randomIndex);
  }

  return id;
}

const slots = defineSlots<{
  label?: () => any;
}>();

const props = withDefaults(defineProps<KisenyaUploaderProps>(), {
  required: false,
});

const modelValue = defineModel({
  required: true,
  type: Array as PropType<Array<KisenyaUploaderFile>>,
});

const fileUploaderRef = ref<HTMLElement | null>(null);

const triggerFileUploader = () => fileUploaderRef.value?.click();

const onFileChange = (event: Event) => {
  const target = <HTMLInputElement>event.target;
  if (target.files)
    modelValue.value = Array.from(target.files).map((file) => ({
      id: generateID(),
      file,
    }));
};

const bytesToMegabytes = (bytes: number) => (bytes / (1024 * 1024)).toFixed(3);

const errorMessage: Ref<KisenyaInputErrorMessage> = ref(false);

const verify = () => {
  if (props.required && !modelValue.value.length) {
    errorMessage.value = "Dieses Feld ist erforderlich.";
  } else errorMessage.value = false;

  return errorMessage.value;
};

const addFiles = (evt: DragEvent) => {
  evt.preventDefault();
  if (evt.dataTransfer?.files) {
    for (const file of evt.dataTransfer.files) {
      modelValue.value.push({
        id: generateID(),
        file,
      });
    }
  }
};

defineExpose({
  verify,
});
</script>
