<template>
  <div class="relative pt-24">
    <img
      src="~/assets/footer_3.png"
      class="absolute left-0 right-0 bottom-0 top-0 h-100% w-100% -z-20"
    />
    <img
      src="~/assets/footer_4.png"
      class="absolute left-0 bottom-0 right-0 top-0 h-100% w-100% -z-10"
    />
    <div class="py-128 px-64">
      <div class="grid grid-cols-1 gap-32 h-100%">
        <div class="col-span-1">
          <LogoWhiteIcon class="w-144" :fontControlled="false" />
        </div>
        <div class="text-white col-span-1">
          <div class="text-contact-title mb-32">Kostenloser Check</div>
          <div class="flex justify-start">
            <KisenyaButton
              @click="dialogs.showDialog('checks_duty')"
              label="Jetzt Vorschriften checken"
              type="bright"
            />
          </div>
        </div>
        <div class="text-white col-span-1">
          <div class="text-contact-title mb-32">Navigation</div>
          <div
            class="text-content-subtitle mb-12 hover:underline underline-offset-4 cursor-pointer select-none"
            @click="$router.push('/about')"
          >
            Über uns
          </div>
          <div
            class="text-content-subtitle mb-12 hover:underline underline-offset-4 cursor-pointer select-none"
            @click="$router.push('/software')"
          >
            Liftmanagement Software
          </div>
          <div
            class="text-content-subtitle mb-12 hover:underline underline-offset-4 cursor-pointer select-none"
            @click="$router.push('/packages')"
          >
            Pakete
          </div>
          <div
            class="text-content-subtitle mb-12 hover:underline underline-offset-4 cursor-pointer select-none"
            @click="dialogs.showDialog('software')"
          >
            Login
          </div>
        </div>
        <div class="text-white col-span-1">
          <div class="text-contact-title mb-32">Legals</div>
          <div
            class="text-content-subtitle mb-12 hover:underline underline-offset-4 cursor-pointer select-none"
            @click="$router.push('/imprint')"
          >
            Impressum
          </div>
          <div
            class="text-content-subtitle mb-12 hover:underline underline-offset-4 cursor-pointer select-none"
            @click="$router.push('/privacy')"
          >
            Datenschutz
          </div>
        </div>
        <div class="text-white col-span-1">
          <div class="text-contact-title mb-32">Kontakt</div>
          <div
            class="flex flex-row gap-24 text-content-subtitle mb-12 hover:underline underline-offset-4 cursor-pointer"
          >
            <div>
              <MapIcon class="w-24" :fontControlled="false" />
            </div>
            <div>
              Ludwig-Pfau-Str.17<br />
              70176 Stuttgart
            </div>
          </div>
          <div
            @click="callPhone"
            class="flex flex-row gap-24 text-content-subtitle mb-12 hover:underline underline-offset-4 cursor-pointer"
          >
            <div>
              <PhoneIcon class="w-24" :fontControlled="false" />
            </div>
            <div>015792388694</div>
          </div>
          <div
            @click="sendMail"
            class="flex flex-row gap-24 text-content-subtitle mb-12 hover:underline underline-offset-4 cursor-pointer"
          >
            <div>
              <MailIcon class="w-24" :fontControlled="false" />
            </div>
            <div>service@kisenya.de</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import MapIcon from "~/assets/icons/placeholder-map.svg";
import PhoneIcon from "~/assets/icons/phone-ring.svg";
import MailIcon from "~/assets/icons/envelope-solid.svg";

import LogoWhiteIcon from "~/assets/logo_white.svg?component";

import { useDialogsStore } from "~/stores/dialogs";

const dialogs = useDialogsStore();

function sendMail() {
  window.open("mailto:services@kisenya.de");
}
function callPhone() {
  window.open("tel:015792388694");
}
</script>
