<template>
  <div
    class="flex justify-between items-center rounded-12 px-16 py-24"
    :class="{
      'bg-error text-brand-white': props.type === 'error',
      'bg-positive text-brand-white': props.type === 'success',
    }"
  >
    <div>{{ props.message }}</div>
    <div>
      <KisenyaButton type="flat" dark round @click="$emit('hide')">
        <template #icon>
          <TimesIcon
            :fontControlled="false"
            class="w-24 stroke-brand-white fill-brand-white"
          />
        </template>
      </KisenyaButton>
    </div>
  </div>
</template>
<script lang="ts" setup>
import TimesIcon from "~/assets/icons/times-solid.svg?component";

const props = defineProps<KisenyaBannerProps>();
</script>
