<template>
  <div class="flex flex-col gap-24">
    <div v-for="field in props.form" :key="field.id" :id="field.id">
      <KisenyaInput
        :ref="(el) => (componentRefs[field.id] = el as VueElementVerifiable)"
        v-if="field.type === 'input'"
        v-bind="field.args as KisenyaInputProps"
        v-model="modelValue[field.id]"
      >
        <template v-if="field.slots && field.slots.label" #label>{{
          field.slots.label
        }}</template>
      </KisenyaInput>
      <KisenyaUploader
        :ref="(el) => (componentRefs[field.id] = el as VueElementVerifiable)"
        v-else-if="field.type === 'uploader'"
        v-bind="field.args as KisenyaUploaderProps"
        v-model="modelValue[field.id]"
      >
        <template v-if="field.slots && field.slots.label" #label>{{
          field.slots.label
        }}</template>
      </KisenyaUploader>
      <KisenyaRadioGroup
        :ref="(el) => (componentRefs[field.id] = el as VueElementVerifiable)"
        v-else-if="field.type === 'radio_group'"
        v-bind="field.args as KisenyaRadioGroupProps"
        v-model="modelValue[field.id]"
      >
        <template v-if="field.slots && field.slots.label" #label>{{
          field.slots.label
        }}</template>
      </KisenyaRadioGroup>
      <KisenyaSelect
        :ref="(el) => (componentRefs[field.id] = el as VueElementVerifiable)"
        v-else-if="field.type === 'select'"
        v-bind="field.args as KisenyaSelectProps"
        v-model="modelValue[field.id]"
      >
        <template v-if="field.slots && field.slots.label" #label>{{
          field.slots.label
        }}</template>
      </KisenyaSelect>
      <KisenyaCheckbox
        :ref="(el) => (componentRefs[field.id] = el as VueElementVerifiable)"
        v-else-if="field.type === 'checkbox'"
        v-bind="field.args as KisenyaCheckboxProps"
        v-model="modelValue[field.id]"
      >
        <template v-if="field.slots && field.slots.label" #label>{{
          field.slots.label
        }}</template>
      </KisenyaCheckbox>
    </div>
  </div>
</template>
<script lang="ts" setup>
const props = defineProps<KisenyaFormBuilderProps>();

const componentRefs = ref<{ [key: string]: VueElementVerifiable }>({});

const modelValue = defineModel({
  type: Object,
});

function scrollToChildInScrollableParent(componentKey: string) {
  const childElement = document.getElementById(componentKey);

  if (!childElement) return;

  childElement.scrollIntoView({ behavior: "smooth" });
}

const verify = () => {
  let verification = true;

  for (const componentKey of Object.keys(componentRefs.value)) {
    const component = componentRefs.value[componentKey];
    let verificationStep = component.verify();
    if (verification && verificationStep) {
      scrollToChildInScrollableParent(componentKey);
    }
    if (verificationStep) verification = false;
  }
  return verification;
};

defineExpose({
  verify,
});
</script>
