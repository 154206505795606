<template>
  <div class="antialiased">
    <div
      :class="{
        'text-brand-white/70': props.dark,
        'text-dark/40': !props.dark,
      }"
      class="text-pricing-point mb-8"
      v-if="!props.required"
    >
      optional
    </div>
    <div
      v-if="$slots.label"
      :class="{
        'mb-8 font-medium': true,
        'text-content-subtitle': props.dark === false,
        'text-white': props.dark === true,
      }"
    >
      <slot name="label"></slot>
    </div>
    <input
      v-if="props.type === 'text'"
      v-model="modelValue"
      :placeholder="props.placeholder"
      :class="{
        'placeholder:text-content-subtitle rounded-8 px-12 py-16 focus:outline-none w-100%': true,
        'placeholder:text-dark/40 border-1 border-dark/40':
          errorMessage === false && props.dark === false,
        'placeholder:text-dark bg-white text-black':
          errorMessage === false && props.dark === true,
        'placeholder:text-error/40 border-error/40 border-2':
          errorMessage !== false && props.dark === false,
        'placeholder:text-error border-error border-2 text-black':
          errorMessage !== false && props.dark === true,
      }"
    />
    <input
      v-else-if="props.type === 'number'"
      v-model.number="modelValue"
      type="number"
      :placeholder="props.placeholder"
      :class="{
        'placeholder:text-content-subtitle rounded-8 px-12 py-16 focus:outline-none w-100%': true,
        'placeholder:text-dark/40 border-1 border-dark/40':
          errorMessage === false && props.dark === false,
        'placeholder:text-dark bg-white text-black':
          errorMessage === false && props.dark === true,
        'placeholder:text-error/40 border-error/40 border-2':
          errorMessage !== false && props.dark === false,
        'placeholder:text-error border-error border-2 text-black':
          errorMessage !== false && props.dark === true,
      }"
    />
    <textarea
      v-else-if="props.type === 'textarea'"
      v-model="modelValue"
      :placeholder="props.placeholder"
      :class="{
        'placeholder:text-content-subtitle rounded-8 px-12 py-16 focus:outline-none w-100%': true,
        'placeholder:text-dark/40 border-1 border-dark/40':
          errorMessage === false && props.dark === false,
        'placeholder:text-dark bg-white text-black':
          errorMessage === false && props.dark === true,
        'placeholder:text-error/40 border-error/40 border-2':
          errorMessage !== false && props.dark === false,
        'placeholder:text-error border-error border-2 text-black':
          errorMessage !== false && props.dark === true,
      }"
    />
    <div
      :class="{
        'mt-4 ': true,
        'text-caption text-error': props.dark === false,
        'text-caption-dark text-error-dark': props.dark === true,
      }"
      v-if="errorMessage"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>
<script lang="ts" setup>
const modelValue = defineModel({
  required: true,
  type: [String, Number] as PropType<string | number>,
});

const errorMessage: Ref<KisenyaInputErrorMessage> = ref(false);

const slots = defineSlots<{
  label?: () => any;
}>();
const props = withDefaults(defineProps<KisenyaInputProps>(), {
  type: "text",
  dark: false,
  required: false,
});

const verify = () => {
  const rules = [] as KisenyaInputRule[];

  if (props.rules && props.rules.length > 0) rules.push(...props.rules);

  if (props.required) {
    rules.push((val) => !!val || "Dieses Feld ist erforderlich.");
  }

  for (const rule of rules) {
    const ruleResponse: KisenyaInputRuleResponse = rule(modelValue.value);
    console.log("rule, ", ruleResponse);
    if (ruleResponse === true) errorMessage.value = false;
    else {
      errorMessage.value = ruleResponse;
      return errorMessage.value;
    }
  }

  return false;
};

watch(modelValue, () => {
  verify();
});

defineExpose({
  verify,
});
</script>
