import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoCourt, LazySvgoAngleDownSolid, LazySvgoArrowRightSolid, LazySvgoBarsSolid, LazySvgoCheckSolid, LazySvgoCheckmarkCircle, LazySvgoCloudUploadAltSolid, LazySvgoCoinsSolid, LazySvgoEnvelopeSolid, LazySvgoEnvelope, LazySvgoFileContractSolid, LazySvgoHomeSolid, LazySvgoMoneyBillWaveAltSolid, LazySvgoPhoneRing, LazySvgoPhoneSolid, LazySvgoPlaceholderMap, LazySvgoProjectDiagramSolid, LazySvgoQuotation, LazySvgoSecureTime, LazySvgoStopwatchSolid, LazySvgoTimesSolid, LazySvgoTrashAltSolid, LazySvgoUserTieSolid } from '#components'
const lazyGlobalComponents = [
  ["SvgoCourt", LazySvgoCourt],
["SvgoAngleDownSolid", LazySvgoAngleDownSolid],
["SvgoArrowRightSolid", LazySvgoArrowRightSolid],
["SvgoBarsSolid", LazySvgoBarsSolid],
["SvgoCheckSolid", LazySvgoCheckSolid],
["SvgoCheckmarkCircle", LazySvgoCheckmarkCircle],
["SvgoCloudUploadAltSolid", LazySvgoCloudUploadAltSolid],
["SvgoCoinsSolid", LazySvgoCoinsSolid],
["SvgoEnvelopeSolid", LazySvgoEnvelopeSolid],
["SvgoEnvelope", LazySvgoEnvelope],
["SvgoFileContractSolid", LazySvgoFileContractSolid],
["SvgoHomeSolid", LazySvgoHomeSolid],
["SvgoMoneyBillWaveAltSolid", LazySvgoMoneyBillWaveAltSolid],
["SvgoPhoneRing", LazySvgoPhoneRing],
["SvgoPhoneSolid", LazySvgoPhoneSolid],
["SvgoPlaceholderMap", LazySvgoPlaceholderMap],
["SvgoProjectDiagramSolid", LazySvgoProjectDiagramSolid],
["SvgoQuotation", LazySvgoQuotation],
["SvgoSecureTime", LazySvgoSecureTime],
["SvgoStopwatchSolid", LazySvgoStopwatchSolid],
["SvgoTimesSolid", LazySvgoTimesSolid],
["SvgoTrashAltSolid", LazySvgoTrashAltSolid],
["SvgoUserTieSolid", LazySvgoUserTieSolid],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
